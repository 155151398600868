import { atom, useRecoilValue } from 'recoil';

import { activeSaleIdState } from '../../stores/sales';
import type { BillingStateQuery } from '../../types/generated-new';
import { useBillingStateQuery } from '../../types/generated-new';

export const billingStateState = atom<
  BillingStateQuery['billingStateV2'] | undefined
>({
  key: 'billingStateState',
  default: undefined,
});

export const useBillingState = () => {
  const activeSaleId = useRecoilValue(activeSaleIdState);

  const billingState = useBillingStateQuery(
    {
      saleId: activeSaleId ?? '',
    },
    {
      enabled: !!activeSaleId,
      refetchOnWindowFocus: false,
      select: (data) => data.billingStateV2,
    }
  );

  return billingState;
};
