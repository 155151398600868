import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useSessionStorage } from 'react-use';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth0 } from '@auth0/auth0-react';

import { useSupportChat } from '../../hooks/use-support-chat';
import { brandState } from '../../stores/brand';
import {
  activeSaleIdState,
  activeSaleState,
  sortedSalesState,
} from '../../stores/sales';
import type { BrandAccess } from '../../types/generated-new';
import { useBrandsAccessQuery } from '../../types/generated-new';
import type { BrandMatch } from '../../types/navigation';
import { BrandView } from '../settings-account/brand-view';
import { SalesSelectorCurrentSale } from './sales-selector-current-sale';
import { SalesSelectorSale } from './sales-selector-sale';

import './sales-selector.scss';

export const SalesSelector: React.FC = () => {
  const { user, logout } = useAuth0();
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { setSupportPlatform } = useSupportChat();
  const brand = useRecoilValue(brandState);

  const { brandId } = useParams<BrandMatch>();
  const [display, setDisplay] = useState(false);

  const [, setRedirectUri] = useSessionStorage<string>('redirectUri');

  const { data: brandAccessData } = useBrandsAccessQuery(undefined, {
    refetchOnWindowFocus: false,
  });
  const [otherDashboards, setOtherDashboards] = useState<BrandAccess[]>([]);

  const sortedSales = useRecoilValue(sortedSalesState);
  const activeSale = useRecoilValue(activeSaleState);
  const setActiveSaleId = useSetRecoilState(activeSaleIdState);

  useEffect(() => {
    if (brandAccessData) {
      setOtherDashboards(
        brandAccessData.getBrandsAccess
          .filter((brandAccess): brandAccess is BrandAccess => !!brandAccess)
          .filter((brand) => brand.brandId !== brandId)
      );

      if (
        user &&
        user?.['https://appchoose.io/claims/isInternalUser'] === false &&
        !brandAccessData.getBrandsAccess
          .filter((brandAccess): brandAccess is BrandAccess => !!brandAccess)
          .find((brand) => brand.brandId === brandId)
      ) {
        setRedirectUri(
          `${location.pathname}/${location.search}${location.hash}`
        );
        logout({
          logoutParams: {
            returnTo: `${window.location.origin}/logout-successful`,
          },
        });
      }
    }
  }, [brandAccessData]);

  const onPress = () => {
    setDisplay(!display);
  };

  const onSelect = (saleId: string) => {
    console.log('onSelect', saleId);
    // On change de vente : on reset les search params et on set le sid
    setSearchParams(
      new URLSearchParams({
        sid: saleId.slice(0, 5),
      })
    );

    setActiveSaleId(saleId);
    const activeSale = sortedSales?.find((sale) => sale.id === saleId);
    setSupportPlatform(activeSale);

    onPress();
  };

  if (sortedSales?.length === 0) {
    return null;
  }

  return (
    <div className="sales-selector">
      <SalesSelectorCurrentSale onPress={onPress} sale={activeSale} />
      {display ? (
        <div
          className="list max-w-[30rem]"
          style={{ width: otherDashboards.length > 0 ? '100vw' : '242px' }}
        >
          {otherDashboards.length > 0 ? (
            <>
              <div className="divide-y divide-gray-100">
                {otherDashboards.map((brand, idx) => (
                  <BrandView
                    key={idx}
                    name={brand.brandName}
                    id={brand.brandId}
                  />
                ))}
              </div>
              <div className="border-t border-gray-100 px-4 pt-6 font-semibold text-gray-700">
                {brand?.name}
              </div>
            </>
          ) : null}

          {sortedSales?.map((sale, idx) => (
            <SalesSelectorSale key={idx} sale={sale} onSelect={onSelect} />
          ))}
        </div>
      ) : null}
      {display ? <div onClick={onPress} className="bg" /> : null}
    </div>
  );
};
